.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90px 1fr 25px;
  height: 100vh;

  min-width: 400px;
  margin-left: auto;
  margin-right: auto;
  grid-template-areas:
    " head "
    " content "
    " footer  ";
}

.App-header {
  grid-area: head;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "logo logo menu";
}

.App-header .App-logo {
  grid-area: logo;
}

.App-body {
  grid-area: content;
  justify-self: center;
  width: 85%;
  max-width: 750px;
}

.App-title {
  font-size: 1.5em;
}
