body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  /* background-image: url("./assets/eemerg_back.jpg");*/
}

@media only screen and (max-width: 768px) {
  .larger {
    display: none;
  }
}

.rbox {
  border: 0.5px dotted red;
}
.gbox {
  border: 1px dotted green;
}
.bbox {
  border: 1px dotted blue;
}
.ybox {
  border: 1px dotted yellow;
}
.pbox {
  border: 1px dotted purple;
}
.nb {
  margin-top: 45px;
}

.copyright {
  padding-left: 10px;
  font-size: 0.5em;
  font-weight: bold;
}

.loading {
  font-size: 15px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.1em;
  }
}

.buildnum {
  font-size: 0.5em;
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 5px;
}
